/*---------------------
  Hero
-----------------------*/

.row-hero {
	height: 20rem;
	width: 100%;
	position: relative;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	background-color: rgba(0, 0, 0, 0.454);
}

	@media(min-width: 480px) {
		.row-hero {
			height: 35rem;
			
		}
	}
	@media(min-width: 720px) {
		.row-hero {
			height: 35rem;
		
		}
	}
	@media(max-width: 1100px) {
		.video {
			object-fit: cover;
			width: 100vw;
			height: 100vh!important;
			position: absolute;;
			top: 0;
			left: 0;
			z-index: -1;
		  }
	}

.video {
  object-fit: cover;
  width: 100vw;
  position: absolute;;
  top: 0;
  left: 0;
  z-index: -1;
}


/* Banner */

#banner {
	padding: 10em 0 8.75em 0 ;
}

	#banner .inner {
		margin: 0 auto;
		width: 55em;
	}

	#banner .logo {
		-moz-transition: opacity 2s ease, -moz-transform 1s ease;
		-webkit-transition: opacity 2s ease, -webkit-transform 1s ease;
		-ms-transition: opacity 2s ease, -ms-transform 1s ease;
		transition: opacity 2s ease, transform 1s ease;
		-moz-transform: translateY(0);
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
		margin: 0 0 1.3em 0;
	}
	.logo{
		margin-top: 10px;
	}

		#banner .logo .icon {
			border-radius: 100%;
			border: solid 2px rgba(255, 255, 255, 0.125);
			cursor: default;
			display: inline-block;
			font-size: 2em;
			height: 2.25em;
			line-height: 2.25em;
			text-align: center;
			width: 2.25em;
		}

	#banner h2 {
		-moz-transition: opacity 0.5s ease, -moz-transform 0.5s ease, -moz-filter 0.25s ease;
		-webkit-transition: opacity 0.5s ease, -webkit-transform 0.5s ease, -webkit-filter 0.25s ease;
		-ms-transition: opacity 0.5s ease, -ms-transform 0.5s ease, -ms-filter 0.25s ease;
		transition: opacity 0.5s ease, transform 0.5s ease, filter 0.25s ease;
		-moz-transform: translateX(0);
		-webkit-transform: translateX(0);
		-ms-transform: translateX(0);
		transform: translateX(0);
		-moz-transition-delay: 0.65s;
		-webkit-transition-delay: 0.65s;
		-ms-transition-delay: 0.65s;
		transition-delay: 0.65s;
		-moz-filter: blur(0);
		-webkit-filter: blur(0);
		-ms-filter: blur(0);
		filter: blur(0);
		opacity: 1;
		border-bottom: solid 2px rgba(255, 255, 255, 0.125);
		font-size: 1.50em;
		margin-bottom: 0.8em;
		padding-bottom: 0.4em;
	}

	#banner p {
		-moz-transition: opacity 0.5s ease, -moz-transform 0.5s ease, -moz-filter 0.25s ease;
		-webkit-transition: opacity 0.5s ease, -webkit-transform 0.5s ease, -webkit-filter 0.25s ease;
		-ms-transition: opacity 0.5s ease, -ms-transform 0.5s ease, -ms-filter 0.25s ease;
		transition: opacity 0.5s ease, transform 0.5s ease, filter 0.25s ease;
		-moz-transform: translateX(0);
		-webkit-transform: translateX(0);
		-ms-transform: translateX(0);
		transform: translateX(0);
		-moz-transition-delay: 0.8s;
		-webkit-transition-delay: 0.8s;
		-ms-transition-delay: 0.8s;
		transition-delay: 0.8s;
		-moz-filter: blur(0);
		-webkit-filter: blur(0);
		-ms-filter: blur(0);
		filter: blur(0);
		opacity: 1;
		font-family: Raleway, Helvetica, sans-serif;
		font-size: 1em;
		font-weight: 200;
		letter-spacing: 0.1em;
		line-height: 2;
		text-transform: uppercase;
	}

	@media screen and (max-width: 1280px) {

		#banner {
			padding: 7em 0 8.25em 0 ;

			background-size: auto,
cover;
			background-position: center,
center;
			
		}

	}

	@media screen and (max-width: 980px) {

		#banner {
			padding: 12em 3em 12.375em 3em ;
			margin-bottom: -4.75em;
		}

			#banner .inner {
				width: 100%;
			}

	}

	@media screen and (max-width: 736px) {

		#banner {
			padding: 5em 2em 4.25em 2em ;
			margin-bottom: -2.5em;
		}

			#banner .logo {
				margin: 0 0 1em 0;
			}

				#banner .logo .icon {
					font-size: 1.5em;
				}

			#banner h2 {
				font-size: 1.5em;
			}

			#banner p {
				font-size: 0.8em;
			}

	}

	body.is-preload #banner .logo {
		-moz-transform: translateY(0.5em);
		-webkit-transform: translateY(0.5em);
		-ms-transform: translateY(0.5em);
		transform: translateY(0.5em);
		opacity: 0;
	}

	body.is-preload #banner h2 {
		opacity: 0;
		-moz-transform: translateX(0.25em);
		-webkit-transform: translateX(0.25em);
		-ms-transform: translateX(0.25em);
		transform: translateX(0.25em);
		-moz-filter: blur(2px);
		-webkit-filter: blur(2px);
		-ms-filter: blur(2px);
		filter: blur(2px);
	}

	body.is-preload #banner p {
		opacity: 0;
		-moz-transform: translateX(0.5em);
		-webkit-transform: translateX(0.5em);
		-ms-transform: translateX(0.5em);
		transform: translateX(0.5em);
		-moz-filter: blur(2px);
		-webkit-filter: blur(2px);
		-ms-filter: blur(2px);
		filter: blur(2px);
	}
	
	
	.plus{
		font-size: 1rem!important;
		margin-top: 0.8rem!important;
	}
