@import url(fontawesome-all.min.css);
@import url("https://fonts.googleapis.com/css?family=Raleway:200,700|Source+Sans+Pro:300,600,300italic,600italic");

.container, .container-lg, .container-md, .container-sm, .container-xl{
	max-width: 1000px;
}

html, body, div, span, applet, object,
iframe, h1, h2, h3, h4, h5, h6, p, blockquote,
pre, a, abbr, acronym, address, big, cite,
code, del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var, b,
u, i, center, dl, dt, dd, ol, ul, li, fieldset,
form, label, legend, table, caption, tbody,
tfoot, thead, tr, th, td, article, aside,
canvas, details, embed, figure, figcaption,
footer, header, hgroup, menu, nav, output, ruby,
section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

	blockquote:before, blockquote:after, q:before, q:after {
		content: '';
		content: none;
	}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
	-webkit-text-size-adjust: none;
}

mark {
	background-color: transparent;
	color: inherit;
}

input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

input, select, textarea {
	-moz-appearance: none;
	-webkit-appearance: none;
	-ms-appearance: none;
	appearance: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: "helvetica neue", helvetica, arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  font-size: 16px;
}

h3 {
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: .5rem;
}

p {
  line-height: 1.8;
  
}

/* Basic */

	@-ms-viewport {
		width: device-width;
	}

	body {
		-ms-overflow-style: scrollbar;
	}

	@media screen and (max-width: 480px) {

		html, body {
			min-width: 320px;
		}

	}

	html {
		box-sizing: border-box;
	}

	*, *:before, *:after {
		box-sizing: inherit;
	}

	body {
	background-color: #fff!important;
	}

		body.is-preload *, body.is-preload *:before, body.is-preload *:after {
			-moz-animation: none !important;
			-webkit-animation: none !important;
			-ms-animation: none !important;
			animation: none !important;
			-moz-transition: none !important;
			-webkit-transition: none !important;
			-ms-transition: none !important;
			transition: none !important;
		}
		
.img{
	border-radius: 5px!important;
}
/* Type */

	body, input, select, textarea {
		color: #ffffff;
		font-family: "Source Sans Pro", Helvetica, sans-serif;
		font-size: 16.5pt;
		font-weight: 300;
		line-height: 1.65;
	}

		@media screen and (max-width: 1680px) {

			body, input, select, textarea {
				font-size: 13pt;
			}

		}

		@media screen and (max-width: 1280px) {

			body, input, select, textarea {
				font-size: 12pt;
			}

		}

		@media screen and (max-width: 980px) {

			body, input, select, textarea {
				font-size: 12pt;
			}

		}

		@media screen and (max-width: 736px) {

			body, input, select, textarea {
				font-size: 12pt;
			}

		}

		@media screen and (max-width: 480px) {

			body, input, select, textarea {
				font-size: 12pt;
			}

		}

	a {
		-moz-transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
		-webkit-transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
		-ms-transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
		transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
		border-bottom: dotted 1px rgba(255, 255, 255, 0.35);
		color: #ffffff;
		text-decoration: none;
	}

		a:hover {
			border-bottom-color: transparent;
			color: #ffffff !important;
			text-decoration: none;
		}

		a.special:not(.button) {
			text-decoration: none;
			border-bottom: 0;
			display: block;
			font-family: Raleway, Helvetica, sans-serif;
			font-size: 0.8em;
			font-weight: 700;
			letter-spacing: 0.1em;
			margin: 0 0 2em 0;
			text-transform: uppercase;
		}

			a.special:not(.button):before {
				-moz-osx-font-smoothing: grayscale;
				-webkit-font-smoothing: antialiased;
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				line-height: 1;
				text-transform: none !important;
				font-family: 'Font Awesome 5 Free';
				font-weight: 900;
			}

			a.special:not(.button):before {
				-moz-transition: background-color 0.2s ease-in-out;
				-webkit-transition: background-color 0.2s ease-in-out;
				-ms-transition: background-color 0.2s ease-in-out;
				transition: background-color 0.2s ease-in-out;
				border-radius: 100%;
				border: solid 2px rgba(255, 255, 255, 0.125);
				content: '\f105';
				display: inline-block;
				font-size: 1.25em;
				height: 2em;
				line-height: 1.75em;
				margin-right: 0.85em;
				text-align: center;
				text-indent: 0.15em;
				vertical-align: middle;
				width: 2em;
			}


			a.white:not(.button):before {
				-moz-transition: background-color 0.2s ease-in-out;
				-webkit-transition: background-color 0.2s ease-in-out;
				-ms-transition: background-color 0.2s ease-in-out;
				transition: background-color 0.2s ease-in-out;
				border-radius: 100%;
				border: solid 2px rgba(87, 87, 87, 0.356);
				content: '\f105';
				display: inline-block;
				font-size: 1.25em;
				height: 2em;
				line-height: 1.75em;
				margin-right: 0.85em;
				text-align: center;
				text-indent: 0.15em;
				vertical-align: middle;
				width: 2em;
			}

			a.white:not(.button):hover:before {
				background-color: rgba(255, 255, 255, 0.025);
			}
			

			a.special:not(.button):hover:before {
				background-color: rgba(255, 255, 255, 0.025);
			}

			a.special:not(.button):active:before {
				background-color: rgba(255, 255, 255, 0.075);
			}

	strong, b {
		color: #ffffff;
		font-weight: 600;
	}

	em, i {
		font-style: italic;
	}

	p {
		margin: 0 0 2em 0;
		font-size: 20px;
	}

	 h3,  h5 {
		color:#fff;
		font-family: Raleway, Helvetica, sans-serif;
		font-weight: 700;
		letter-spacing: 0.1em;
		margin: 0 0 1em 0;
		text-transform: uppercase;
	}

		h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
			color: inherit;
			text-decoration: none;
			border-bottom: 0;
		}

		h1 span, h2 span, h3 span, h4 span, h5 span, h6 span {
			font-weight: 200;
		}

		h1.major, h2.major, h3.major, h4.major, h5.major, h6.major {
			padding-bottom: 1em;
			border-bottom: solid 2px rgba(255, 255, 255, 0.125);
		}

	h2 {
		font-size: 1.2em;
	}

	h3 {
		font-size: 0.9em;
	}

	h4 {
		font-size: 0.7em;
	}

	h5 {
		font-size: 0.7em;
	}

	h6 {
		font-size: 0.7em;
	}

	.major{
		color: #fff;
		border-bottom: solid 2px rgba(186, 186, 186, 0.31);
		padding-bottom: 0.2em!important;
		font-size: 2em;
		letter-spacing: 0.2rem;
	}

	.major-1{
		color: #000;
		border-bottom: solid 2px rgba(186, 186, 186, 0.31);
		padding-bottom: 0.2em;
		font-size: 2em;
		letter-spacing: 0.2rem;

	}

	.major-2{
		color: #000!important;
	}


	.white{
	-webkit-transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
    -ms-transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
    border-bottom: dotted 1px rgba(255, 255, 255, 0.35);
    color: #000!important;
    text-decoration: none;
	}

	.white:hover{
		-webkit-transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
		-ms-transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
		transition: color 0.2s ease-in-out, border-bottom-color 0.2s ease-in-out;
		border-bottom: dotted 1px rgba(255, 255, 255, 0.35);
		color: rgba(0, 0, 0, 0.543)!important;
		text-decoration: none;
		}

	@media screen and (max-width: 736px) {

		h2 {
			font-size: 1em;
		}

		h3 {
			font-size: 0.8em;
		}

	}

	sub {
		font-size: 0.8em;
		position: relative;
		top: 0.5em;
	}

	sup {
		font-size: 0.8em;
		position: relative;
		top: -0.5em;
	}

	blockquote {
		border-left: solid 4px rgba(255, 255, 255, 0.125);
		font-style: italic;
		margin: 0 0 2em 0;
		padding: 0.5em 0 0.5em 2em;
	}

	

/* Box */

	.box {
		border-radius: 5px;
		border: solid 2px rgba(255, 255, 255, 0.125);
		margin-bottom: 2em;
		padding: 1.5em;
	}

		.box > :last-child,
		.box > :last-child > :last-child,
		.box > :last-child > :last-child > :last-child {
			margin-bottom: 0;
		}

		.box.alt {
			border: 0;
			border-radius: 0;
			padding: 0;
		}

/* Icon */

	.icon {
		text-decoration: none;
		border-bottom: none;
		position: relative;
	}

		.icon:before {
			-moz-osx-font-smoothing: grayscale;
			-webkit-font-smoothing: antialiased;
			display: inline-block;
			font-style: normal;
			font-variant: normal;
			text-rendering: auto;
			line-height: 1;
			text-transform: none !important;
			font-family: 'Font Awesome 5 Free';
			font-weight: 400;
		}

		.icon > .label {
			display: none;
		}

		.icon:before {
			line-height: inherit;
		}

		.icon.solid:before {
			font-weight: 900;
		}

		.icon.brands:before {
			font-family: 'Font Awesome 5 Brands';
		}

/* Actions */
	
	ul.actions {
		display: -moz-flex;
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
		cursor: default;
		list-style: none;
		margin-left: -1em;
		padding-left: 0;
	}

		ul.actions li {
			padding: 0 0 0 1em;
			vertical-align: middle;
		}

		ul.actions.special {
			-moz-justify-content: center;
			-webkit-justify-content: center;
			-ms-justify-content: center;
			justify-content: center;
			width: 100%;
			margin-left: 0;
		}

			ul.actions.special li:first-child {
				padding-left: 0;
			}

		ul.actions.stacked {
			-moz-flex-direction: column;
			-webkit-flex-direction: column;
			-ms-flex-direction: column;
			flex-direction: column;
			margin-left: 0;
		}

			ul.actions.stacked li {
				padding: 1.3em 0 0 0;
			}

				ul.actions.stacked li:first-child {
					padding-top: 0;
				}

		ul.actions.fit {
			width: calc(100% + 1em);
		}

			ul.actions.fit li {
				-moz-flex-grow: 1;
				-webkit-flex-grow: 1;
				-ms-flex-grow: 1;
				flex-grow: 1;
				-moz-flex-shrink: 1;
				-webkit-flex-shrink: 1;
				-ms-flex-shrink: 1;
				flex-shrink: 1;
				width: 100%;
			}

				ul.actions.fit li > * {
					width: 100%;
				}

			ul.actions.fit.stacked {
				width: 100%;
			}

		@media screen and (max-width: 480px) {

			ul.actions:not(.fixed) {
				-moz-flex-direction: column;
				-webkit-flex-direction: column;
				-ms-flex-direction: column;
				flex-direction: column;
				margin-left: 0;
				width: 100% !important;
			}

				ul.actions:not(.fixed) li {
					-moz-flex-grow: 1;
					-webkit-flex-grow: 1;
					-ms-flex-grow: 1;
					flex-grow: 1;
					-moz-flex-shrink: 1;
					-webkit-flex-shrink: 1;
					-ms-flex-shrink: 1;
					flex-shrink: 1;
					padding: 1em 0 0 0;
					text-align: center;
					width: 100%;
				}

					ul.actions:not(.fixed) li > * {
						width: 100%;
					}

					ul.actions:not(.fixed) li:first-child {
						padding-top: 0;
					}

					ul.actions:not(.fixed) li input[type="submit"],
					ul.actions:not(.fixed) li input[type="reset"],
					ul.actions:not(.fixed) li input[type="button"],
					ul.actions:not(.fixed) li button,
					ul.actions:not(.fixed) li .button {
						width: 100%;
					}

						ul.actions:not(.fixed) li input[type="submit"].icon:before,
						ul.actions:not(.fixed) li input[type="reset"].icon:before,
						ul.actions:not(.fixed) li input[type="button"].icon:before,
						ul.actions:not(.fixed) li button.icon:before,
						ul.actions:not(.fixed) li .button.icon:before {
							margin-left: -0.5rem;
						}

		}

/* Icons */

	ul.icons {
		cursor: default;
		list-style: none;
		padding-left: 0;
	}

		ul.icons li {
			display: inline-block;
			padding: 0 1em 0 0;
		}

			ul.icons li:last-child {
				padding-right: 0;
			}

			ul.icons li .icon:before {
				font-size: 1.25em;
			}

/* Contact */

	ul.contact {
		list-style: none;
		padding: 0;
	}

		ul.contact li {
			text-decoration: none;
			margin: 2.5em 0 0 0;
			padding: 0 0 0 3.25em;
			position: relative;
		}

			ul.contact li:before {
				-moz-osx-font-smoothing: grayscale;
				-webkit-font-smoothing: antialiased;
				display: inline-block;
				font-style: normal;
				font-variant: normal;
				text-rendering: auto;
				line-height: 1;
				text-transform: none !important;
				font-family: 'Font Awesome 5 Free';
				font-weight: 400;
			}

			ul.contact li:before {
				border-radius: 100%;
				border: solid 2px rgba(255, 255, 255, 0.125);
				display: inline-block;
				font-size: 0.8em;
				height: 2.5em;
				left: 0;
				line-height: 2.35em;
				position: absolute;
				text-align: center;
				top: 0;
				width: 2.5em;
			}

			ul.contact li:first-child {
				margin-top: 0;
			}

		@media screen and (max-width: 736px) {

			ul.contact li {
				margin: 1.5em 0 0 0;
			}

		}



/* Button */

	input[type="submit"],
	input[type="reset"],
	input[type="button"],
	button,
	.button {
		-moz-appearance: none;
		-webkit-appearance: none;
		-ms-appearance: none;
		appearance: none;
		-moz-transition: background-color 0.2s ease-in-out;
		-webkit-transition: background-color 0.2s ease-in-out;
		-ms-transition: background-color 0.2s ease-in-out;
		transition: background-color 0.2s ease-in-out;
		background-color: #59B327;
		border-radius: 5px;
		border: 0;
		box-shadow: inset 0 0 0 2px #59B327;
		color: #ffffff !important;
		cursor: pointer;
		display: inline-block;
		font-family: Raleway, Helvetica, sans-serif;
		font-size: 0.8em;
		font-weight: 700;
		height: 3.75em;
		letter-spacing: 0.1em;
		line-height: 3.75em;
		padding: 0 2.25em;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;
		white-space: nowrap;
	}

		input[type="submit"]:hover,
		input[type="reset"]:hover,
		input[type="button"]:hover,
		button:hover,
		.button:hover {
			background-color: #668714;
		}

		input[type="submit"]:active,
		input[type="reset"]:active,
		input[type="button"]:active,
		button:active,
		.button:active {
			background-color: rgba(255, 255, 255, 0.075);
		}

		input[type="submit"].icon:before,
		input[type="reset"].icon:before,
		input[type="button"].icon:before,
		button.icon:before,
		.button.icon:before {
			margin-right: 0.5em;
			color: rgba(255, 255, 255, 0.35);
		}

		input[type="submit"].primary,
		input[type="reset"].primary,
		input[type="button"].primary,
		button.primary,
		.button.primary {
			background-color: #4c5c96;
			box-shadow: none;
		}

			input[type="submit"].primary:hover,
			input[type="reset"].primary:hover,
			input[type="button"].primary:hover,
			button.primary:hover,
			.button.primary:hover {
				background-color: #53639e;
			}

			input[type="submit"].primary:active,
			input[type="reset"].primary:active,
			input[type="button"].primary:active,
			button.primary:active,
			.button.primary:active {
				background-color: #45558d;
			}

			input[type="submit"].primary.icon:before,
			input[type="reset"].primary.icon:before,
			input[type="button"].primary.icon:before,
			button.primary.icon:before,
			.button.primary.icon:before {
				color: #7985b0;
			}

		input[type="submit"].fit,
		input[type="reset"].fit,
		input[type="button"].fit,
		button.fit,
		.button.fit {
			width: 100%;
		}

		input[type="submit"].small,
		input[type="reset"].small,
		input[type="button"].small,
		button.small,
		.button.small {
			font-size: 0.6em;
		}

		input[type="submit"].large,
		input[type="reset"].large,
		input[type="button"].large,
		button.large,
		.button.large {
			font-size: 1em;
		}

		input[type="submit"].disabled, input[type="submit"]:disabled,
		input[type="reset"].disabled,
		input[type="reset"]:disabled,
		input[type="button"].disabled,
		input[type="button"]:disabled,
		button.disabled,
		button:disabled,
		.button.disabled,
		.button:disabled {
			opacity: 0.25;
		}

		@media screen and (max-width: 480px) {

			input[type="submit"],
			input[type="reset"],
			input[type="button"],
			button,
			.button {
				padding: 0;
			}

		}

/* Features */

	.features {
		display: -moz-flex;
		display: -webkit-flex;
		display: -ms-flex;
		display: flex;
		-moz-flex-wrap: wrap;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin: 0 0 2em 0;
	}

	.text{
		color: #eee!important;
		-webkit-text-stroke: 0.7px;
	}

		.features article {
			padding: 1.75em 1.75em 0.1em 1.75em ;
			background-color: #59B327;
			border-radius: 5px;
			margin: 1.5em 3em 1.5em 0;
			width: calc(50% - 1.5em);
		}

			.features article:nth-child(2n) {
				margin-right: 0;
			}

			.features article .image {
				border-radius: 5px 5px 0 0;
				display: block;
				margin-bottom: 1.75em;
				margin-left: -1.75em;
				margin-top: -1.75em;
				position: relative;
				width: calc(100% + 3.5em);
			}

				.features article .image img {
					border-radius: 5px 5px 0 0;
					width: 100%;
				}

		@media screen and (max-width: 980px) {

			.features article {
				margin: 1em 2em 1em 0;
				width: calc(50% - 1em);
			}

		}

		@media screen and (max-width: 736px) {

			.features article {
				padding: 1.5em 1.5em 0.1em 1.5em ;
				margin: 0.875em 1.75em 0.875em 0;
				width: calc(50% - 0.875em - 1px);
			}

				.features article .image {
					margin-bottom: 1.5em;
					margin-left: -1.5em;
					margin-top: -1.5em;
					width: calc(100% + 3em);
				}

		}

		@media screen and (max-width: 480px) {

			.features {
				display: block;
			}

				.features article {
					width: 100%;
					margin: 0 0 2em 0 !important;
				}

		}




/* Wrapper */


	.wrapper {
		background-color: #fff;
	
		
		position: relative;
	}

	

		.wrapper:before {
			left: 0;
			top: -6.5em;
		}

		.wrapper:after {
			-moz-transform: scaleY(-1);
			-webkit-transform: scaleY(-1);
			-ms-transform: scaleY(-1);
			transform: scaleY(-1);
			bottom: -6.5em;
			left: 0;
		}

		.wrapper.alt:before {
		
			
		}

		.wrapper.alt:after {
			-moz-transform: scaleY(-1) scaleX(-1);
			-webkit-transform: scaleY(-1) scaleX(-1);
			-ms-transform: scaleY(-1) scaleX(-1);
			transform: scaleY(-1) scaleX(-1);
		}

		.wrapper .inner {
			padding: 3em 0 1em 0 ;
			margin: 0 auto;
			width: 45em;
		}

		.wrapper.style2 {
			background-color: #353849;
		}

			.wrapper.style2:before, .wrapper.style2:after {
				background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon points='0,100 100,0 100,100' style='fill:%23353849%3B' /%3E%3C/svg%3E");
			}

			.wrapper.style2:before {
				box-shadow: inset 0 -1px 0 0 #353849, 0 1px 0 0 #353849;
			}

			.wrapper.style2:after {
				box-shadow: inset 0 -1px 0 0 #353849, 0 1px 0 0 #353849;
			}

		.wrapper.style3 {
			background-color: #3d4051;
		}

			.wrapper.style3:before, .wrapper.style3:after {
				background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon points='0,100 100,0 100,100' style='fill:%233d4051%3B' /%3E%3C/svg%3E");
			}

			.wrapper.style3:before {
				box-shadow: inset 0 -1px 0 0 #3d4051, 0 1px 0 0 #3d4051;
			}

			.wrapper.style3:after {
				box-shadow: inset 0 -1px 0 0 #3d4051, 0 1px 0 0 #3d4051;
			}

		.wrapper.style4 {
			background-color: #454858;
		}

			.wrapper.style4:before, .wrapper.style4:after {
				background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon points='0,100 100,0 100,100' style='fill:%23454858%3B' /%3E%3C/svg%3E");
			}

			.wrapper.style4:before {
				box-shadow: inset 0 -1px 0 0 #454858, 0 1px 0 0 #454858;
			}

			.wrapper.style4:after {
				box-shadow: inset 0 -1px 0 0 #454858, 0 1px 0 0 #454858;
			}

		.wrapper.style5 {
			background-color: #4d5060;
		}

			.wrapper.style5:before, .wrapper.style5:after {
				background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon points='0,100 100,0 100,100' style='fill:%234d5060%3B' /%3E%3C/svg%3E");
			}

			.wrapper.style5:before {
				box-shadow: inset 0 -1px 0 0 #4d5060, 0 1px 0 0 #4d5060;
			}

			.wrapper.style5:after {
				box-shadow: inset 0 -1px 0 0 #4d5060, 0 1px 0 0 #4d5060;
			}

		.wrapper.style6 {
			background-color: #555867;
		}

			.wrapper.style6:before, .wrapper.style6:after {
				background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon points='0,100 100,0 100,100' style='fill:%23555867%3B' /%3E%3C/svg%3E");
			}

			.wrapper.style6:before {
				box-shadow: inset 0 -1px 0 0 #555867, 0 1px 0 0 #555867;
			}

			.wrapper.style6:after {
				box-shadow: inset 0 -1px 0 0 #555867, 0 1px 0 0 #555867;
			}

		.wrapper.spotlight {
			background-color: #4c5c96;
			padding-top: -5px!important;
		}

			.wrapper.spotlight:before, .wrapper.spotlight:after {
				background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon points='0,100 100,0 100,100' style='fill:%234c5c96%3B' /%3E%3C/svg%3E");
			}

			.wrapper.spotlight:before {
				box-shadow: inset 0 -1px 0 0 #4c5c96, 0 1px 0 0 #4c5c96;
			}

			.wrapper.spotlight:after {
				box-shadow: inset 0 -1px 0 0 #4c5c96, 0 1px 0 0 #4c5c96;
			}

			.wrapper.spotlight .inner {
				display: -moz-flex;
				display: -webkit-flex;
				display: -ms-flex;
				display: flex;
				-moz-align-items: center;
				-webkit-align-items: center;
				-ms-align-items: center;
				align-items: center;
				-moz-flex-direction: row;
				-webkit-flex-direction: row;
				-ms-flex-direction: row;
				flex-direction: row;
			}

			.wrapper.spotlight .image {
				border-radius: 100%;
				margin: 0 3em 2em 0;
				width: 22em;
				overflow: hidden;
				-ms-flex: 1;
			}

				.wrapper.spotlight .image img {
					border-radius: 100%;
					width: 100%;
				}

			.wrapper.spotlight .content {
				width: 100%;
				-ms-flex: 2;
			}

			.wrapper.spotlight:nth-child(2n - 1) .inner {
				-moz-flex-direction: row-reverse;
				-webkit-flex-direction: row-reverse;
				-ms-flex-direction: row-reverse;
				flex-direction: row-reverse;
				text-align: right;
			}

			.wrapper.spotlight:nth-child(2n - 1) .image {
				margin: 0 0 2em 3em;
			}

			.wrapper.spotlight.style2 {
				background-color: #45558d;
			}

				.wrapper.spotlight.style2:before, .wrapper.spotlight.style2:after {
					background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon points='0,100 100,0 100,100' style='fill:%2345558d%3B' /%3E%3C/svg%3E");
				}

				.wrapper.spotlight.style2:before {
					box-shadow: inset 0 -1px 0 0 #45558d, 0 1px 0 0 #45558d;
				}

				.wrapper.spotlight.style2:after {
					box-shadow: inset 0 -1px 0 0 #45558d, 0 1px 0 0 #45558d;
				}

			.wrapper.spotlight.style3 {
				background-color: #3f4e85;
			}

				.wrapper.spotlight.style3:before, .wrapper.spotlight.style3:after {
					background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon points='0,100 100,0 100,100' style='fill:%233f4e85%3B' /%3E%3C/svg%3E");
				}

				.wrapper.spotlight.style3:before {
					box-shadow: inset 0 -1px 0 0 #3f4e85, 0 1px 0 0 #3f4e85;
				}

				.wrapper.spotlight.style3:after {
					box-shadow: inset 0 -1px 0 0 #3f4e85, 0 1px 0 0 #3f4e85;
				}

			.wrapper.spotlight.style4 {
				background-color: #39477c;
			}

				.wrapper.spotlight.style4:before, .wrapper.spotlight.style4:after {
					background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon points='0,100 100,0 100,100' style='fill:%2339477c%3B' /%3E%3C/svg%3E");
				}

				.wrapper.spotlight.style4:before {
					box-shadow: inset 0 -1px 0 0 #39477c, 0 1px 0 0 #39477c;
				}

				.wrapper.spotlight.style4:after {
					box-shadow: inset 0 -1px 0 0 #39477c, 0 1px 0 0 #39477c;
				}

			.wrapper.spotlight.style5 {
				background-color: #324072;
			}

				.wrapper.spotlight.style5:before, .wrapper.spotlight.style5:after {
					background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon points='0,100 100,0 100,100' style='fill:%23324072%3B' /%3E%3C/svg%3E");
				}

				.wrapper.spotlight.style5:before {
					box-shadow: inset 0 -1px 0 0 #324072, 0 1px 0 0 #324072;
				}

				.wrapper.spotlight.style5:after {
					box-shadow: inset 0 -1px 0 0 #324072, 0 1px 0 0 #324072;
				}

			.wrapper.spotlight.style6 {
				background-color: #2d3a69;
			}

				.wrapper.spotlight.style6:before, .wrapper.spotlight.style6:after {
					background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon points='0,100 100,0 100,100' style='fill:%232d3a69%3B' /%3E%3C/svg%3E");
				}

				.wrapper.spotlight.style6:before {
					box-shadow: inset 0 -1px 0 0 #2d3a69, 0 1px 0 0 #2d3a69;
				}

				.wrapper.spotlight.style6:after {
					box-shadow: inset 0 -1px 0 0 #2d3a69, 0 1px 0 0 #2d3a69;
				}

		@media screen and (max-width: 980px) {

			.wrapper {
				margin: 4.75em 0;
			}

				.wrapper:before, .wrapper:after {
					height: 4.75em;
				}

				.wrapper:before {
					top: -4.75em;
				}

				.wrapper:after {
					bottom: -4.75em;
					left: 0;
				}

				.wrapper .inner {
					padding: 3em 3em 1em 3em ;
					width: 100%;
				}

				.wrapper.spotlight .image {
					margin: 0 2em 2em 0;
					width: 32em;
				}

				.wrapper.spotlight:nth-child(2n - 1) .image {
					margin: 0 0 2em 2em;
				}

		}

		@media screen and (max-width: 736px) {

			.wrapper {
				margin: 2.5em 0;
			}

				.wrapper:before, .wrapper:after {
					height: 2.5em;
				}

				.wrapper:before {
					top: -2.5em;
				}

				.wrapper:after {
					bottom: -2.5em;
					left: 0;
				}

				.wrapper .inner {
					padding: 2em 2em 0.1em 2em ;
				}

				.wrapper.spotlight .inner {
					-moz-align-items: -moz-flex-start;
					-webkit-align-items: -webkit-flex-start;
					-ms-align-items: -ms-flex-start;
					align-items: flex-start;
				}

				.wrapper.spotlight .image {
					width: 19em;
					margin: 0 1.75em 2em 0;
				}

				.wrapper.spotlight:nth-child(2n - 1) .image {
					margin: 0 0 2em 1.75em;
				}

		}

		@media screen and (max-width: 480px) {

			.wrapper.spotlight .inner {
				display: block;
			}

			.wrapper.spotlight .image {
				margin: 0 0 1em 0 !important;
				max-width: 85%;
				width: 12em;
			}

		}

		@media screen and (max-width: 360px) {

			.wrapper .inner {
				padding: 2em 1.5em 0.1em 1.5em ;
			}

		}



.article{
	color: #000!important;
	border-bottom: dotted 1px rgba(114, 114, 114, 0.35)!important;
}

.article:hover{
	color: rgba(0, 0, 0, 0.591)!important;
	border-bottom: dotted 1px rgba(114, 114, 114, 0.35)!important;
}



/*sections*/


header {
  display: block;
  padding: 20px 0;
  background-color: #eee;
}

header h1 {
  font-size: 1.8rem;
  text-align: center;
}

.container-1 {
	display: block;
	width: 100%;
	overflow: hidden;
	padding-top: 150px;
}

.slanted {
	margin: 0 -50px;
	transform-origin: left center;
	
}

.slanted:nth-child(odd) {
	transform: rotate(1.5deg);
	margin-top: -200px;
	background-image: linear-gradient(45deg, #52910f 0%, #59B327 100%);
	box-shadow: 0px 1px 25px 0px rgba(0,0,0,0.25);
}

.slanted:nth-child(even) {
	background: #fafafa;
	transform: rotate(-1.5deg);
	
  	box-shadow: 0px -10px 25px 0px rgba(0,0,0,0.25);
}

.content {
  width: 900px;
  max-width: 100%;
  margin: 0 auto;
  text-align: center;
}

.slanted:nth-child(odd) .content {
	transform: rotate(-1.5deg) translate3d( 0, 0, 0);
	color: #fafafa;
	padding: 150px 100px 165px 100px;
}

.slanted:nth-child(even) .content {
	transform: rotate(1.5deg) translate3d( 0, 0, 0);
	color: #000;
	padding: 150px 100px 275px 100px;
}

/*---------------------
  About
-----------------------*/


  
 
.about-img{
	height: 500px!important;
	width: 100%!important;
	margin-top: 2rem;
}

.about {
	margin-top: -8rem;

	overflow: hidden;

}

.about-page {
	padding-top: 120px;
	padding-bottom: 90px;
}


.about__text.about__page__text {
	padding-top: 0;
}

.about__text.about__page__text .section-title {
	margin-bottom: 12px;
}

.about__text .section-title {
	margin-bottom: 35px;
}

.about__para__text {
	margin-bottom: 70px;
}

.about__para__text p {
	margin-bottom: 26px;
}

.about__para__text p:last-child {
	margin-bottom: 0;
}

.about__pic {
	padding-left: 34px;
}

.about__pic__inner {
	position: relative;
	display: inline-block;
	margin-top:8.3rem;
}

.about__page__services__text {
	margin-bottom: 30px;
}

.border-bottom{
    border-color: #cecece;
    border-style: solid;
    width: 300px;
    border-bottom-width: 1px;
    margin-top: 10px;
	margin-bottom: 16px;
	text-align: center!important;
	position: relative; 
    display: inline-block;
    vertical-align: middle;
    
    margin: 10px 0;
}

hr{
	margin-top: 0.1em;
	margin-bottom: 0.1em;
}

.clearfix:after {
    clear: both;
}

.team__title{
	color: #000!important;
}
.p-articles{
	color: #000!important;
}
/*new animation*/

  
  .animatable {
	
	/* initially hide animatable objects */
	visibility: hidden;
	
	/* initially pause animatable objects their animations */
	-webkit-animation-play-state: paused;   
	-moz-animation-play-state: paused;     
	-ms-animation-play-state: paused;
	-o-animation-play-state: paused;   
	animation-play-state: paused; 
  }
  
  /* show objects being animated */
  .animated {
	visibility: visible;
	
	-webkit-animation-fill-mode: both;
	-moz-animation-fill-mode: both;
	-ms-animation-fill-mode: both;
	-o-animation-fill-mode: both;
	animation-fill-mode: both;
	
	-webkit-animation-duration: 1s;
	-moz-animation-duration: 1s;
	-ms-animation-duration: 1s;
	-o-animation-duration: 1s;
	animation-duration: 1s;
  
	-webkit-animation-play-state: running;
	-moz-animation-play-state: running;
	-ms-animation-play-state: running;
	-o-animation-play-state: running;
	animation-play-state: running;
  }
  
  
  @-webkit-keyframes fadeInUp {
	  0% {
		  opacity: 0;
		  -webkit-transform: translateY(20px);
	  }	100% {
		  opacity: 1;
		  -webkit-transform: translateY(0);
	  }
  }
  
  @-moz-keyframes fadeInUp {
	  0% {
		  opacity: 0;
		  -moz-transform: translateY(20px);
	  }
  
	  100% {
		  opacity: 1;
		  -moz-transform: translateY(0);
	  }
  }
  
  @-o-keyframes fadeInUp {
	  0% {
		  opacity: 0;
		  -o-transform: translateY(20px);
	  }
  
	  100% {
		  opacity: 1;
		  -o-transform: translateY(0);
	  }
  }
  
  @keyframes fadeInUp {
	  0% {
		  opacity: 0;
		  transform: translateY(20px);
	  }
  
	  100% {
		  opacity: 1;
		  transform: translateY(0);
	  }
  }
  
  .animated.animationDelay{
	  animation-delay:.4s;
	  -webkit-animation-delay:.4s;
  }
  .animated.animationDelayMed{
	  animation-delay:1.2s;
	  -webkit-animation-delay:1.2s;
  }
  .animated.animationDelayLong{
	  animation-delay:1.6s;
	  -webkit-animation-delay:1.6s;
  }
  .animated.fadeBgColor {
	  -webkit-animation-name: fadeBgColor;
	  -moz-animation-name: fadeBgColor;
	  -o-animation-name: fadeBgColor;
	  animation-name: fadeBgColor;
  }

  .animated.fadeInUp {
	  -webkit-animation-name: fadeInUp;
	  -moz-animation-name: fadeInUp;
	  -o-animation-name: fadeInUp;
	  animation-name: fadeInUp;
  }



