/*---------------------
  About
-----------------------*/

.about-img{
	height: 500px!important;
	width: 100%!important;
	margin-top: 2rem;
}

.about {
	margin-top: -8rem;

	overflow: hidden;

}

.about-page {
	padding-top: 120px;
	padding-bottom: 90px;
}


.about__text.about__page__text {
	padding-top: 0;
}

.about__text.about__page__text .section-title {
	margin-bottom: 12px;
}

.about__text .section-title {
	margin-bottom: 35px;
}

.about__para__text {
	margin-bottom: 70px;
}

.about__para__text p {
	margin-bottom: 26px;
}

.about__para__text p:last-child {
	margin-bottom: 0;
}

.about__pic {
	padding-left: 34px;
}

.about__pic__inner {
	position: relative;
	display: inline-block;
	margin-top:8.3rem;
}

.about__page__services__text {
	margin-bottom: 30px;
}

.border-bottom{
    border-color: #cecece;
    border-style: solid;
    width: 300px;
    border-bottom-width: 1px;
    margin-top: 10px;
	margin-bottom: 16px;
	text-align: center!important;
	position: relative; 
    display: inline-block;
    vertical-align: middle;
    
    margin: 10px 0;
}

.clearfix:after {
    clear: both;
}

.team__title{
	color: #000!important;
}


.about-1{
	color: #000!important;
}



