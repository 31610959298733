/*---------------------
  Contact/Footer
-----------------------*/
#footer .inner {
    padding: 5em 0 3em 0 ;
    display: -moz-flex;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -moz-flex-direction: row;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -moz-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 auto;
    width: 60em;
}

    #footer .inner > * {
        width: 100%;
    }

    #footer .inner form {
        margin: 0 3em 0 0;
        width: calc(50% - 1.5em);
    }

    #footer .inner .contact {
        width: calc(50% - 1.5em);
    }

    #footer .inner .copyright {
        border-top: solid 2px rgba(255, 255, 255, 0.125);
        list-style: none;
        margin: 4em 0 2em 0;
        padding: 2em 0 0 0;
        width: 100%;
    }

    .map{
        margin-bottom: 2rem;
        border-radius: 5px;
    }

        #footer .inner .copyright li {
            border-left: solid 2px rgba(255, 255, 255, 0.125);
            color:#eee;
            display: inline-block;
            font-size: 0.9em;
            line-height: 1;
            margin-left: 1em;
            padding: 0;
            padding-left: 1em;
        }

            #footer .inner .copyright li:first-child {
                border-left: 0;
                margin-left: 0;
                padding-left: 0;
            }

            #footer .inner .copyright li a {
                color: inherit;
            }


    #footer {
        
        background-image: linear-gradient(to top, rgba(0, 0, 0, 0.81), rgba(0, 0, 0, 0.564)), url("../images/be-contact.jpg");
        background-size: auto,
cover;
        background-position: center,
center;
        margin-top: -6.5em;
        padding-top: 6.5em;
    }



@media screen and (max-width: 1280px) {



}

@media screen and (max-width: 980px) {

    #footer {
        margin-top: -4.75em;
        padding-top: 4.75em;
    }

        #footer .inner {
            padding: 3em 3em 1em 3em ;
            display: block;
            width: 100%;
        }

            #footer .inner form {
                width: 100%;
                margin: 0 0 4em 0;
            }

            #footer .inner .contact {
                width: 100%;
                margin: 0 0 4em 0;
            }

            #footer .inner .copyright {
                margin: 4em 0 2em 0;
            }

}

@media screen and (max-width: 736px) {

    #footer {
        margin-top: -2.5em;
        padding-top: 2.5em;
    }

        #footer .inner {
            padding: 2em 2em 0.1em 2em ;
        }

            #footer .inner form {
                margin: 0 0 3em 0;
            }

            #footer .inner .contact {
                margin: 0 0 3em 0;
            }

}

@media screen and (max-width: 480px) {

    #footer .inner .copyright li {
        border-left: 0;
        display: block;
        margin: 1em 0 0 0;
        padding-left: 0;
    }

        #footer .inner .copyright li:first-child {
            margin-top: 0;
        }

}

@media screen and (max-width: 360px) {

    #footer .inner {
        padding: 2em 1.5em 0.1em 1.5em ;
    }

}


label {
    color: #ffffff;
    display: block;
    font-family: Raleway, Helvetica, sans-serif;
    font-size: 0.8em;
    font-weight: 700;
    letter-spacing: 0.1em;
    margin: 0 0 0.7em 0;
    text-transform: uppercase;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
select,
textarea {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    background: rgba(255, 255, 255, 0.025);
    border-radius: 5px;
    border: none;
    border: solid 2px rgba(255, 255, 255, 0.125);
    color: inherit;
    display: block;
    outline: 0;
    padding: 0 1em;
    text-decoration: none;
    width: 100%;
}

    input[type="text"]:invalid,
    input[type="password"]:invalid,
    input[type="email"]:invalid,
    input[type="tel"]:invalid,
    select:invalid,
    textarea:invalid {
        box-shadow: none;
    }

    input[type="text"]:focus,
    input[type="password"]:focus,
    input[type="email"]:focus,
    input[type="tel"]:focus,
    select:focus,
    textarea:focus {
        border-color: #5b6ba6;
    }

select {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'%3E%3Cpath d='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='rgba(255, 255, 255, 0.125)' /%3E%3C/svg%3E");
    background-size: 1.25rem;
    background-repeat: no-repeat;
    background-position: calc(100% - 1rem) center;
    height: 2.75em;
    padding-right: 2.75em;
    text-overflow: ellipsis;
}

    select option {
        color: #ffffff;
        background: #2e3141;
    }

    select:focus::-ms-value {
        background-color: transparent;
    }

    select::-ms-expand {
        display: none;
    }

input[type="text"],
input[type="password"],
input[type="email"],
select {
    height: 2.75em;
}

textarea {
    padding: 0.75em 1em;
}

input[type="checkbox"],
input[type="radio"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    display: block;
    float: left;
    margin-right: -2em;
    opacity: 0;
    width: 1em;
    z-index: -1;
}

    input[type="checkbox"] + label,
    input[type="radio"] + label {
        text-decoration: none;
        color: #ffffff;
        cursor: pointer;
        display: inline-block;
        font-size: 1em;
        font-family: "Source Sans Pro", Helvetica, sans-serif;
        text-transform: none;
        letter-spacing: 0;
        font-weight: 300;
        padding-left: 2.4em;
        padding-right: 0.75em;
        position: relative;
    }

        input[type="checkbox"] + label:before,
        input[type="radio"] + label:before {
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
            text-transform: none !important;
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
        }

     
    input[type="checkbox"]:checked + label:before,
    input[type="radio"]:checked + label:before {
        background: #ffffff;
        border-color: #ffffff;
        content: '\f00c';
        color: #2e3141;
    }

    input[type="checkbox"]:focus + label:before,
    input[type="radio"]:focus + label:before {
        border-color: #4c5c96;
    }

input[type="checkbox"] + label:before {
    border-radius: 5px;
}

input[type="radio"] + label:before {
    border-radius: 100%;
}

::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.35) !important;
    opacity: 1.0;
}

:-moz-placeholder {
    color: rgba(255, 255, 255, 0.35) !important;
    opacity: 1.0;
}

::-moz-placeholder {
    color: rgba(255, 255, 255, 0.35) !important;
    opacity: 1.0;
}

:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.35) !important;
    opacity: 1.0;
}

/* Contact */

ul.contact {
    list-style: none;
    padding: 0;
}

    ul.contact li {
        text-decoration: none;
        margin: 2.5em 0 0 0;
        padding: 0 0 0 3.25em;
        position: relative;
    }

        ul.contact li:before {
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
            text-transform: none !important;
            font-family: 'Font Awesome 5 Free';
            font-weight: 400;
        }

        ul.contact li:before {
            border-radius: 100%;
            border: solid 2px rgba(255, 255, 255, 0.125);
            display: inline-block;
            font-size: 0.8em;
            height: 2.5em;
            left: 0;
            line-height: 2.35em;
            position: absolute;
            text-align: center;
            top: 0;
            width: 2.5em;
        }

        ul.contact li:first-child {
            margin-top: 0;
        }

    @media screen and (max-width: 736px) {

        ul.contact li {
            margin: 1.5em 0 0 0;
        }

    }