
/*---------------------
Header
-----------------------*/

// Moves menu to the left/right side of screen
$menu-orientation: right;

@media screen and (max-width: 600px) {
  .pushNav {
    width: 75%;
    #{$menu-orientation}: -75%
  }
}

@media screen and (min-width: 601px) {
  .pushNav {
    width: 350px;
    #{$menu-orientation}: -350px;
  }
}

ul.pushNav {
  padding: 0;
  margin: 0;
  z-index: 900;
  list-style-type: none;
  position: fixed;
    top: 0;
    bottom:0;

   
    height: 100vh; /* viewport height */
    overflow-y: scroll;
    overflow-x: hidden;
}

.pushNav {
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 900;
  z-index: 100;
  overflow: hidden;
  background: #59b327;
  transition: ease-in-out 0.5s;
    hr {
    border: 1px solid #555;
  }
}

 .pushNav ,.pushNav a {
   font-size: 0.91em;
   font-family: helvetica, sens-serif;
   font-weight: 100;
   color: #fff;
   text-decoration: none;
}

.pushNavIsOpen {
  overflow: auto;
  height: 100%;
}

.js-topPushNav.isOpen, .pushNav_level.isOpen {
 #{$menu-orientation} : 0;
}

.closeLevel, .openLevel {
  cursor: pointer;
}

.openLevel, .closeLevel, .pushNav a {
  padding: 1em 0;
  display: block;
  text-indent: 20px;
  transition: background 0.4s ease-in-out;

  &:hover {
    background: #52910f;
  }
}

.hdg {
	background-color: #52910f;
	color: #fff!important;
}

.closeLevel, closelevel > i {
  font-size: 1em;
  color: #fff;
}

.burger {
  position: absolute;
  top: 24px;
  #{$menu-orientation}: 120px;
  
  i {
	font-size: 3em;
	display: none!important;
  }
}

.open{
	position: fixed;
	z-index: 200;
}
.close{
	margin-top: 0.2rem;
	float: left;
	margin-right: -1.5rem;
}
.screen {
  position: fixed;
  background: rgba(0, 0, 0, 0.543);
  width: 100%;
  height: 0;
  top: 0;
  bottom: 0;
  z-index: 700;
  #{$menu-orientation}: 0;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  backdrop-filter: blur(3px)!important;
}

.pushNavIsOpen .screen {
  height: 100%;
  opacity: 1;
}

